.d-block {
  display: block;
}

.btn-success {
  color: #fff !important;
  background-color: #333a42 !important;
  border-color: #2e343b !important;
}

.appContainer {
  margin: 15px;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow-y: auto;
}

header {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.appContainer__main {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0;
}

.appContainer__main__phonePlaceholder {
  max-width: 290px;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 25px;
  color: #000;
  padding-bottom: 5px;
  letter-spacing: 2px;
  border-bottom: 3px solid #2e343b;
}

.appContainer__main__keyPadGrp {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
  max-width: 400px;
}

.appContainer__main__keyPadGrp > button {
  margin: 10px;
  flex: 0 1 auto;
  width: 100px;
  height: 100px;
  background: #ddd;
  border-radius: 100%;
  font-size: 20px;
  color: #000;
}

.appContainer__main__keyPadGrp > button:hover {
  background: #d1d0d0;
}

.appContainer__main__keyPadGrp--next {
  background: #2e343b !important;
  color: #fff !important;
}

.appContainer__newCustomer {
  margin: 15px 0;
}

.appContainer__main__finalScreen {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.consentText {
  text-align: center !important;
  max-width: 300px;
  margin: 0 auto !important;
  font-weight: 100 !important;
}

.booking-service-container {
  max-height: 600px;
  overflow: auto;
}

.appContainer__pickServices {
  padding: 0;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.booking-service-category-container {
  height: auto;
}

.swiper {
  border: 1px solid rgb(231, 232, 233);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  padding: 10px 40px;
  border-radius: 50px;
  background: #fff;
}

.swiper-slide {
  height: auto;
  box-sizing: border-box;
  align-self: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
}

.swiper-pagination-bullet-active {
  background: #006d9c;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 10px !important;
  font-weight: bold;
  background: #333;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-text-stroke: 1.5px #fff;
}

.booking-service-category-container {
  height: auto;
}

.booking-service-category-item {
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 50px;
  display: grid;
  place-items: center;
  height: 100%;
  transition: background 500ms ease;
  font-size: 14px;
  font-weight: 400;
}

.booking-service-category-item.selected,
.booking-service-category-item:hover {
  background: #dce9e1;
}

.category-heading {
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 0;
}

.booking-service-category-items {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  border-radius: 8px;
}

.booking-service-category-items .service-item {
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid rgb(231, 232, 233);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
}

.service-item input[type="checkbox"] {
  transform: scale(1.4);
}

.booking-service-category-items .service-item .service-item-name {
  padding: 0 16px;
  flex: 2;
}

.service-item-name-heading {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.booking-service-category-content {
  padding: 15px;
  max-height: 500px;
  overflow-y: auto;
}

.booking-service-category-content-item {
  margin-bottom: 40px;
}

.booking-card-container {
  display: flex;
  flex-flow: column;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin: 20px 0;
}

.booking-card-item {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgb(231, 232, 233);
  cursor: pointer;
}

.booking-card-item:hover {
  background: #f7f8fa;
}

.time-block-item {
  font-weight: bold;
}

.time-block-item.disabled {
  cursor: not-allowed;
  touch-action: none;
}

.staff-container,
.booking-time-container {
  max-height: 700px;
  overflow-y: auto;
}

.staff-item img {
  width: 50px;
  border-radius: 50px;
  aspect-ratio: 1/1;
}

.staff-name {
  font-weight: bold;
}

.service-duration {
  font-size: 13px;
  display: block;
}

@media (max-width: 768px) {
  .appContainer__main {
    flex-flow: row wrap;
  }
}

@media (max-width: 400px) {
  .appContainer__main__keyPadGrp > button {
    width: 80px;
    height: 80px;
  }
}
